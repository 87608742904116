import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ComponentCard from '../../../components/ComponentCard';
import userService from '../../../services/UserService';
import { DejaVuSans } from '../fonts/DejaVuSans';
import licenseService from '../../../services/LicenseService';
 
const Teachers = () => {
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [masterFranchisors, setMasterFranchisors] = useState([]);
  const [lcfs, setLcfs] = useState([]);
  const [licences, setLicences] = useState([]);
  const [passwordError, setPasswordError] = useState('');
  const user = JSON.parse(localStorage.getItem('user'));
  const [formData, setFormData] = useState({
    name: '',
    lname: '',
    email: '',
    password: '',
    active: '1',
    in_bussines: '1',
    master: masterFranchisors.length === 1 ? user.master_id : '',
    lcf: '',
    licence: [],
  });

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setFormData({
        name: '',
        lname: '',
        email: '',
        password: '',
        active: '1',
        in_bussines: '1',
        master: user.role === 'master' ? user._id :user.role === 'lcf' ? user.master_n :'',
        lcf: user.role === 'lcf' ? user._id :'',
        licence: [],
      });
    }
  };

  const getUsers = async () => {
    try {
      const response = await userService.getByRole('teacher');
      setUsers(response);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const getMasterFranchisors = async () => {
    try {
      const response = await userService.getByRole('master');
      setMasterFranchisors(response);
    } catch (error) {
      console.error('Error fetching master franchisors:', error);
    }
  };

  const getLcfs = async () => {
    try {
      const response = await userService.getByRole('lcf');
      setLcfs(response);
    } catch (error) {
      console.error('Error fetching LCFs:', error);
    }
  };

  const getLicences = async () => {
    try {
      const response = await licenseService.getAll();
      setLicences(response);
    } catch (error) {
      console.error('Error fetching licences:', error);
    }
  };

  useEffect(() => {
    getUsers();
    getMasterFranchisors();
    getLcfs();
    getLicences();
  }, []);
  const validatePasswords = (password, repassword) => {
    if (password && repassword && password !== repassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === 'repassword' || name === 'password') {
      validatePasswords(
        name === 'password' ? value : formData.password,
        name === 'repassword' ? value : formData.repassword,
      );
    }
  };

  const handleLicenceChange = (event) => {
    //eslint-disable-next-line
    const options = event.target.options;
    const selectedLicences = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedLicences.push({ $oid: options[i].value });
      }
    }
    console.log(selectedLicences);

    setFormData((prevState) => ({
      ...prevState,
      licence: selectedLicences,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userData = {
      ...formData,
      active: formData.active === '1' ? 1 : 0,
      role: 'teacher',
      master: formData.master,
      master_n:formData.master,
    };

    try {
      if (formData._id) {
        if (formData.password === '111111') {
          delete userData.password;
        }
        await userService.updateUser(formData._id, userData);
      } else {
        await userService.createUser(userData);
      }
      getUsers();
      toggleModal();
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  const handleEdit = (user1) => {
    setFormData({
      _id: user1._id,
      name: user1.name,
      lname: user1.lname,
      email: user1.email,
      password: '111111',
      repassword: '111111',
      active: user1.active ? '1' : '0',
      in_bussines: user1.in_bussines ? '1' : '0',
      master:user.role ==="master" ?user._id :user.role ==="lcf"?user1.master_n : user1.master[0].$oid,
      lcf: user.role ==="lcf" ?user._id : user1.lcf,
      licence: user1.licence,
    });
    setModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await userService.deleteUser(id);
        getUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  const columns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (row) => `${row.name} ${row.lname}`,
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Master Franchisor',
      id: 'masters',
      accessor: (row) => (row.masters ? `${row.masters.name} ${row.masters.lname}` : 'N/A'),
    },

    {
      Header: 'LCF email',
      id: 'lcf_email',
      accessor: (row) => {
        const lcf = lcfs.find((l) => l._id === row.lcf);
        return lcf ? `${lcf.email}` : 'N/A';
      },
    },
    {
      Header: 'LCF',
      id: 'lcf',
      accessor: (row) => {
        const lcf = lcfs.find((l) => l._id === row.lcf);
        return lcf ? `${lcf.name} ${lcf.lname}` : 'N/A';
      },
    },
    {
      Header: 'Licenses',
      id: 'licence',
      accessor: (row) => {
        return row.licence?.map((lic) => {
            const licence = licences.find((l) => l._id === lic.$oid);
            return licence ? licence.name : 'N/A';
          })
          .join(', ');
      },
    },
    {
      Header: 'Status',
      accessor: 'active',
      Cell: (props) => (
        <Badge color={props.value ? 'success' : 'danger'} pill>
          {props.value ? 'Active' : 'Inactive'}
        </Badge>
      ),
    },
    {
      Header: 'In bussines',
      accessor: 'in_bussines',
      Cell: (props) => (
        <Badge color={props.value ? 'success' : 'danger'} pill>
          {props.value ? 'Yes' : 'No'}
        </Badge>
      ),
    },
    {
      Header: 'Actions',
      Cell: ({ original }) => (
        <>
          <Button color="info" size="sm" onClick={() => handleEdit(original)}>
            Edit
          </Button>{' '}
          <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  const exportPDF = (columnsall, dataToExport) => {
    // ... (keep your existing exportPDF function)
  };

  return (
    <>
      <ComponentCard title={`Teachers (${users.length ?? 0})`}>
        <Button color="primary" onClick={toggleModal}>
          Add New Teacher
        </Button>
        <Button
          color="secondary"
          onClick={() => exportPDF(columns, filteredData.length ? filteredData : users)}
        >
          Print to PDF
        </Button>

        <ReactTable
          columns={columns}
          defaultPageSize={50}
          className="-striped -highlight myTable"
          data={users}
          filterable
        />
      </ComponentCard>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>
            {formData._id ? 'Edit Teacher' : 'Add New Teacher'}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="master">Master Franchisor</Label>
              <Input
                type="select"
                name="master"
                id="master"
                value={formData.master}
                onChange={handleInputChange}
                disabled={masterFranchisors.length === 1 && user.role !== 'admin'}
              >
                <option value="">Select Master Franchisor</option>
                {masterFranchisors.map((master) => (
                  <option key={master._id} value={master._id}>
                    {`${master.name} ${master.lname} - ${master.email}`}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="lcf">LCF</Label>
              <Input
                type="select"
                name="lcf"
                id="lcf"
                value={formData.lcf}
                onChange={handleInputChange}
                disabled={lcfs.length === 1 && user.role !== 'admin' && user.role !== 'master'}
              >
                <option value="">Select LCF</option>
                {lcfs.map((lcf) => (
                  <option
                    key={lcf._id}
                    value={lcf._id}
                  >{`${lcf.name} ${lcf.lname} - ${lcf.email}`}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="lname">Last Name</Label>
              <Input
                type="text"
                name="lname"
                id="lname"
                value={formData.lname}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                type="password"
                name="password"
                id="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="repassword">Re-enter Password</Label>
              <Input
                type="password"
                name="repassword"
                id="repassword"
                value={formData.repassword}
                onChange={handleInputChange}
                invalid={!!passwordError}
                required
              />
              <FormFeedback>{passwordError}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="licence">Licence</Label>
              <Input
                type="select"
                name="licence"
                id="licence"
                multiple
                value={formData.licence?.map((l) => l.$oid)}
                onChange={handleLicenceChange}
              >
                {licences.map((licence) => (
                  <option key={licence._id} value={licence._id}>
                    {licence.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="active">Active</Label>
              <Input
                type="select"
                name="active"
                id="active"
                value={formData.active}
                onChange={handleInputChange}
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="in_bussines">In bussines</Label>
              <Input
                type="select"
                name="in_bussines"
                id="in_bussines"
                value={formData.in_bussines}
                onChange={handleInputChange}
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Save
            </Button>{' '}
            <Button color="secondary" onClick={toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default Teachers;
