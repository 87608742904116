import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

import ComponentCard from '../../../components/ComponentCard';
import centerService from '../../../services/CenterService';
import userService from '../../../services/UserService';
import { DejaVuSans } from '../fonts/DejaVuSans';

const LCFCenters = () => {
  const [centers, setCenters] = useState([]);
  const [modal, setModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [masterFranchisors, setMasterFranchisors] = useState([]);
  const [lcfs, setLcfs] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    masterFranchisor: '',
    lcf: '',
    address: '',
    town: '',
    zipCode: '',
    phone: '',
    licencePrice: '',
    notes: '',
    active: '1'
  });

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setFormData({
        name: '',
        masterFranchisor: '',
        lcf: '',
        address: '',
        town: '',
        zipCode: '',
        phone: '',
        licencePrice: '',
        notes: '',
        active: '1'
      });
    }
  };

  const getCenters = async () => {
    try {
      const response = await centerService.getAll();
      setCenters(response);
      setFilteredData(response);
    } catch (error) {
      console.error('Error fetching centers:', error);
    }
  };

  const getMasterFranchisors = async () => {
    try {
      const response = await userService.getByRole('master');
      setMasterFranchisors(response);
    } catch (error) {
      console.error('Error fetching master franchisors:', error);
    }
  };

  const getLcfs = async () => {
    try {
      const response = await userService.getByRole('lcf');
      setLcfs(response);
    } catch (error) {
      console.error('Error fetching LCFs:', error);
    }
  };

  useEffect(() => {
    getCenters();
    getMasterFranchisors();
    getLcfs();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const centerData = { ...formData, active: formData.active === '1' };
    
    try {
      if (formData._id) {
        await centerService.updateCenter(formData._id, centerData);
      } else {
        await centerService.createCenter(centerData);
      }
      getCenters();
      toggleModal();
    } catch (error) {
      console.error('Error saving center:', error);
    }
  };

  const handleEdit = (center) => {
    setFormData({
      _id: center._id,
      name: center.name,
      masterFranchisor: center.masterFranchisor,
      lcf: center.lcf,
      address: center.address,
      town: center.town,
      zipCode: center.zipCode,
      phone: center.phone,
      licencePrice: center.licencePrice.replace('EUR ', ''),
      notes: center.notes,
      active: center.active ? '1' : '0'
    });
    setModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this center?')) {
      try {
        await centerService.deleteCenter(id);
        getCenters();
      } catch (error) {
        console.error('Error deleting center:', error);
      }
    }
  };

  const columns = [
    {
      Header: 'LCF Center name',
      accessor: 'name',
    },
    {
      Header: 'Master Franchisor',
      id: 'master',
      accessor: (row) => {
        const lcf = lcfs.find(l => l._id === row.lcf);
        const masterFranchisor = masterFranchisors.find(mf => mf._id === lcf?.masters?._id);
         return masterFranchisor ? `${masterFranchisor.name} ${masterFranchisor.lname}` : 'N/A';
      },
       
    },
    {
      Header: 'LCF',
      id: 'lcf',
      accessor: (row) => {
        const lcf = lcfs.find(l => l._id === row.lcf);
        return lcf ? `${lcf.name} ${lcf.lname}` : 'N/A';
      },
       
    },
    {
      Header: 'Address',
      accessor: 'adresa',
    },
    {
      Header: 'Town',
      accessor: 'grad',
    },
    {
      Header: 'Zip code',
      accessor: 'zip',
    },
    {
      Header: 'Status',
      accessor: 'active',
      Cell: props => (
        <Badge color={props.value ? 'success' : 'danger'} pill>
          {props.value ? 'Active' : 'Inactive'}
        </Badge>
      )
    },
    {
      Header: 'Created at',
      accessor: 'createdAt',
      Cell: ({ value }) => moment(value).format('DD.MM.YYYY')
    },
    {
      Header: 'Actions',
      Cell: ({ original }) => (
        <>
          <Button color="info" size="sm" onClick={() => handleEdit(original)}>Edit</Button>
          {' '}
          <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>Delete</Button>
        </>
      )
    }
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    // eslint-disable-next-line new-cap
    const doc = new jsPDF(orientation, unit, size);

    doc.setFont("DejaVuSans", "normal");
    doc.setFontSize(15);

    const title = "LCF Centers Report";
    const headers = columns.map(column => column.Header);

    const data = filteredData.map(item => [
      item.name,
      item.masterFranchisor.split('@')[0],
      item.lcf.split('@')[0],
      item.address,
      item.town,
      item.zipCode,
      item.active ? 'Active' : 'Inactive',
      new Date(item.createdAt).toLocaleDateString()
    ]);

    const content = {
      startY: 50,
      head: [headers],
      body: data
    };

    doc.text(title, 40, 40);
    doc.autoTable(content);
    doc.save("lcf_centers_report.pdf")
  }

  return (
    <>
      <ComponentCard title={`LCF Centers (${centers.length ?? 0})`}>
        <div style={{marginBottom: '10px'}}>
          <Button color="primary" onClick={toggleModal} style={{marginRight: '10px'}}>Add New Center</Button>
          <Button color="secondary" onClick={exportPDF}>Print to PDF</Button>
        </div>
        <ReactTable
          columns={columns}
          data={centers}
          filterable
          defaultPageSize={50}
          className="-striped -highlight myTable"
         /*  onFilteredChange={(filtered, column, value) => {
            const filteredData1 = centers.filter(item => {
              return filtered.every(filter => {
                const columnValue = columns.find(c => c.accessor === filter.id).accessor(item);
                return columnValue.toLowerCase().includes(filter.value.toLowerCase());
              });
            });
            setFilteredData(filteredData1);
          }} */
        />
      </ComponentCard>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>{formData._id ? 'Edit LCF Center' : 'Add New LCF Center'}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="masterFranchisor">MF</Label>
              <Input type="select" name="masterFranchisor" id="masterFranchisor" value={formData.masterFranchisor} onChange={handleInputChange} required>
                <option value="">Select Master Franchisor</option>
                {masterFranchisors.map(mf => (
                  <option key={mf._id} value={mf.email}>{mf.email}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="licencePrice">Licence price</Label>
              <Input type="text" name="licencePrice" id="licencePrice" value={formData.licencePrice} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="lcf">LCF</Label>
              <Input type="select" name="lcf" id="lcf" value={formData.lcf} onChange={handleInputChange} required>
                <option value="">Select LCF</option>
                {lcfs.map(lcf => (
                  <option key={lcf._id} value={lcf.email}>{lcf.email}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="name">Title</Label>
              <Input type="text" name="name" id="name" value={formData.name} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="address">Address</Label>
              <Input type="text" name="address" id="address" value={formData.address} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="town">City</Label>
              <Input type="text" name="town" id="town" value={formData.town} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="zipCode">Zip code</Label>
              <Input type="text" name="zipCode" id="zipCode" value={formData.zipCode} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input type="text" name="phone" id="phone" value={formData.phone} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="notes">Notes</Label>
              <Input type="textarea" name="notes" id="notes" value={formData.notes} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="active">Active</Label>
              <Input type="select" name="active" id="active" value={formData.active} onChange={handleInputChange}>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">Save</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default LCFCenters;