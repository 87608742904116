import fetchRequest from '../auth/FetchInterceptorRequests'

const eventService = {}

eventService.getAll = function () {
    return fetchRequest({
        url: '/digital/events',
        method: 'get'
    })
}

eventService.get = function (data) {
    return fetchRequest({
        url: `/digital/events/${data.id}`,
        method: 'get'
    })
}

eventService.create = function (data) {
    return fetchRequest({
        url: '/digital/events',
        method: 'post',
        data: data
    })
}

eventService.update = function (data) {
    return fetchRequest({
        url: `/digital/events/${data.id}`,
        method: 'put',
        data: data
    })
}

eventService.delete = function (data) {
    return fetchRequest({
        url: `/digital/events/${data.id}`,
        method: 'delete'
    })
}

eventService.getByDateRange = function (data) {
    return fetchRequest({
        url: '/digital/events/range',
        method: 'get',
        params: {
            startDate: data.startDate,
            endDate: data.endDate
        }
    })
}

eventService.getByType = function (data) {
    return fetchRequest({
        url: '/digital/events/type',
        method: 'get',
        params: {
            type: data.type
        }
    })
}

export default eventService;