import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  CardBody,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Col, Row, Typography, Card } from 'antd';
import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';
import ComponentCard from '../../../components/ComponentCard';
import userService from '../../../services/UserService';
import coursesServices from '../../../services/CoursesService';
import eventService from '../../../services/EventService';
import calendarService from '../../../services/CalendarService';
import studentService from '../../../services/StudentService';

const { Title } = Typography;

const StudentList = styled.div`
  padding: 8px;
  min-height: 300px;
  background-color: ${(props) => (props.isDraggingOver ? '#e6f7ff' : '#f0f2f5')};
`;

const StudentItem = styled.div`
  user-select: none;
  padding: 8px;
  margin-bottom: 3px;
  background-color: ${(props) => (props.isDragging ? '#fafafa' : 'white')};
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;

const Groups = () => {
  const [events, setEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [masters, setMasters] = useState([]);
  const [lcf, setLcf] = useState([]);
  const [studentModal, setStudentModal] = useState(false);
  const [calendarEntries, setCalendarEntries] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [calendarRange, setCalendarRange] = useState({ start: null, end: null });
  const [selectedCalendarEntry, setSelectedCalendarEntry] = useState(null);
  const [eventStudents, setEventStudents] = useState([]);
  const [students, setStudents] = useState([]);
  const [softDeletedStudents, setSoftDeletedStudents] = useState([]);
  const [filterText, setFilterText] = useState('');

  const [allStudents, setAllStudents] = useState([]);
  const [assignedStudents, setAssignedStudents] = useState([]);
  const [unassignedStudents, setUnassignedStudents] = useState([]);

  const filteredUnassignedStudents = unassignedStudents.filter((student) =>
    `${student.name} ${student.lname}`.toLowerCase().includes(filterText.toLowerCase()),
  );
  const [formData, setFormData] = useState({
    name: '',
    start: null,
    end: null,
    timestart: null,
    timeend: null,
    type: 'group',
    course: '',
    teacher: '',
    master: '',
    lcf: '',
    active: true,
  });

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setFormData({
        name: '',
        start: null,
        end: null,
        timestart: null,
        timeend: null,
        type: 'group',
        course: '',
        teacher: '',
        master: '',
        lcf: '',
        active: true,
      });
    }
  };

  const toggleStudentModal = () => {
    setStudentModal(!studentModal);
  };

  const getLcf = async () => {
    try {
      const response = await userService.getByRole('lcf');
      setLcf(response);
    } catch (error) {
      console.error('Error fetching lcf:', error);
    }
  };

  const getMasters = async () => {
    try {
      const response = await userService.getByRole('master');
      setMasters(response);
    } catch (error) {
      console.error('Error fetching masters:', error);
    }
  };

  const getEvents = async () => {
    try {
      const response = await eventService.getAll();
      setEvents(response);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const getTeachers = async () => {
    try {
      const response = await userService.getByRole('teacher');
      setTeachers(response);
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  };

  const getCourses = async () => {
    try {
      const response = await coursesServices.getAll();
      setCourses(response);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const getStudents = async () => {
    try {
      const students1 = await studentService.getAll();
      setAllStudents(students1);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  useEffect(() => {
    getMasters();
    getLcf();
    getEvents();
    getTeachers();
    getCourses();
    getStudents();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (date, name) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: date,
    }));

    if (name === 'start') {
      // Suggest end date 45 weeks from start date
      const suggestedEndDate = new Date(date);
      suggestedEndDate.setDate(suggestedEndDate.getDate() + 45 * 7);
      setFormData((prevState) => ({
        ...prevState,
        end: suggestedEndDate,
      }));
    } else if (name === 'timestart') {
      // Suggest end time 45 minutes from start time
      const suggestedEndTime = new Date(date);
      suggestedEndTime.setMinutes(suggestedEndTime.getMinutes() + 45);
      setFormData((prevState) => ({
        ...prevState,
        timeend: suggestedEndTime,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const eventData = {
      ...formData,
      start: moment(formData.start).format('YYYY-MM-DD'),
      end: moment(formData.end).format('YYYY-MM-DD'),
      timestart: moment(formData.timestart).format('HH:mm'),
      timeend: moment(formData.timeend).format('HH:mm'),
      master: formData.master.toString(),
      lcf: formData.lcf.toString(),
      course: formData.course.toString(),
      teacher: formData.teacher.toString(),
    };

    try {
      if (formData._id) {
        await eventService.update(formData._id, eventData);
      } else {
        await eventService.create(eventData);
      }
      getEvents();
      toggleModal();
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };

  const handleEdit = (event) => {
    setFormData({
      _id: event._id,
      name: event.name,
      start: new Date(event.start),
      end: new Date(event.end),
      timestart: new Date(`1970-01-01T${event.timestart}`),
      timeend: new Date(`1970-01-01T${event.timeend}`),
      type: event.type,
      course: event.course,
      teacher: event.teacher,
      master: event.master,
      lcf: event.lcf,
      active: event.active,
    });
    setModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        await eventService.deleteEvent(id);
        getEvents();
      } catch (error) {
        console.error('Error deleting event:', error);
      }
    }
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const handleManageStudents = async (event) => {
    setSelectedEvent(event);
    try {
      const entries = await calendarService.getCalendarEntriesForEvent(event._id);
      setCalendarEntries(entries.data);
      setSelectedCalendarEntry(null);
      setAssignedStudents([]);
      setUnassignedStudents([]);
      const softDeleted = await calendarService.getSoftDeletedStudentsForEvent(event._id);
      setSoftDeletedStudents(softDeleted.data);

      toggleStudentModal();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCalendarEntrySelect = async (entryId) => {
    setSelectedCalendarEntry(entryId);
    try {
      const assignedStudents1 = await calendarService.getStudentsForCalendarEntry(entryId);
      setAssignedStudents(assignedStudents1);
      setUnassignedStudents(
        allStudents.filter(
          (student) => !assignedStudents1.some((assigned) => assigned._id === student._id),
        ),
      );
    } catch (error) {
      console.error('Error fetching students for calendar entry:', error);
    }
  };
  const handleAddStudentToSubsequentEntries = async (calendarEntryId, studentId) => {
    try {
      await calendarService.addStudentToSubsequentEntries(calendarEntryId, studentId);
      handleCalendarEntrySelect(selectedCalendarEntry);
    } catch (error) {
      console.error('Error adding student to subsequent entries:', error);
    }
  };

  const handleRemoveStudentFromSubsequentEntries = async (calendarEntryId, studentId) => {
    try {
      await calendarService.removeStudentFromSubsequentEntries(calendarEntryId, studentId);
      handleCalendarEntrySelect(selectedCalendarEntry);
    } catch (error) {
      console.error('Error removing student from subsequent entries:', error);
    }
  };
  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      // Reordering within the same list
      const items = reorder(
        source.droppableId === 'assigned' ? assignedStudents : unassignedStudents,
        source.index,
        destination.index,
      );

      if (source.droppableId === 'assigned') {
        setAssignedStudents(items);
      } else {
        setUnassignedStudents(items);
      }
    } else {
      // Moving between lists
      const sourceList = source.droppableId === 'assigned' ? assignedStudents : unassignedStudents;
      const destList =
        destination.droppableId === 'assigned' ? assignedStudents : unassignedStudents;

      if (destination.droppableId === 'assigned' && destList.length >= 10) {
        // Prevent adding more than 10 students to assigned list
        return;
      }

      const resultt = move(sourceList, destList, source, destination);

      setAssignedStudents(resultt.assigned || []);
      setUnassignedStudents(resultt.unassigned || []);

      // Update the backend
      const studentId = sourceList[source.index]._id;
      if (destination.droppableId === 'assigned') {
        await calendarService.addStudentToCalendarEntry(selectedCalendarEntry, studentId);
      } else {
        await calendarService.removeStudentFromCalendarEntry(selectedCalendarEntry, studentId);
      }

      // Refresh the lists after drag and drop
      handleCalendarEntrySelect(selectedCalendarEntry);
    }
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Day',
      id: 'day',
      accessor: (row) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const date = new Date(row.start);
        return `${days[date.getDay()]}`;
      },
    },
    {
      Header: 'Time',
      id: 'time',
      accessor: (row) => {
        // return HH:mm formta from date value 2023-09-15T15:45:00.662Z
        return `${moment(row.timestart, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}-${moment(
          row.timeend,
          'YYYY-MM-DDTHH:mm:ss',
        ).format('HH:mm')}`;
      },
    },

    {
      Header: 'LCF',
      id: 'lcf',
      accessor: (row) => {
        const lcf1 = lcf.find((l) => l._id === row.lcf);
        return lcf1 ? `${lcf1.email}` : 'N/A';
      },
    },
    {
      Header: 'Teacher',
      id: 'teacher',
      accessor: (row) => {
        const teacher = teachers.find((t) => t._id === row.teacher);
        return teacher ? `${teacher.name} ${teacher.lname}` : 'N/A';
      },
    },
    {
      Header: 'Course',
      accessor: 'course',
      Cell: ({ value }) => {
        const course = courses.find((c) => c._id === value);
        return course ? course.name : 'N/A';
      },
    },
    {
      Header: 'Created at',
      accessor: 'createdAt',
      Cell: ({ value }) => moment(value).format('DD.MM.YYYY'),
    },
    {
      Header: 'Actions',
      Cell: ({ original }) => (
        <>
          <Button color="success" size="sm" onClick={() => handleManageStudents(original)}>
            Manage Students
          </Button>{' '}
          <Button color="info" size="sm" onClick={() => handleEdit(original)}>
            Edit
          </Button>{' '}
          <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>
            Delete
          </Button>{' '}
          <Badge color={original.active ? 'success' : 'danger'} pill>
            {original.active ? 'Active' : 'Inactive'}
          </Badge>
        </>
      ),
    },
  ];

  return (
    <>
      <ComponentCard title={`Groups (${events.length ?? 0})`}>
        <Button color="primary" onClick={toggleModal} style={{ marginBottom: '10px' }}>
          Add New Group
        </Button>
        <ReactTable
          columns={columns}
          data={events}
          filterable
          defaultPageSize={10}
          className="-striped -highlight myTable"
        />
      </ComponentCard>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>
            {formData._id ? 'Edit Group' : 'Add New Group'}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <Row>
              <Col span={12}>
                <FormGroup>
                  <Label for="start">Start Date</Label>
                  <DatePicker
                    selected={formData.start}
                    onChange={(date) => handleDateChange(date, 'start')}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    required
                  />
                </FormGroup>
              </Col>
              <Col span={12}>
                <FormGroup>
                  <Label for="end">End Date</Label>
                  <DatePicker
                    selected={formData.end}
                    onChange={(date) => handleDateChange(date, 'end')}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <FormGroup>
                  <Label for="timestart">Time of start</Label>
                  <DatePicker
                    selected={formData.timestart}
                    onChange={(date) => handleDateChange(date, 'timestart')}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    className="form-control"
                    required
                  />
                </FormGroup>
              </Col>
              <Col span={12}>
                <FormGroup>
                  <Label for="timeend">Time of end</Label>
                  <DatePicker
                    selected={formData.timeend}
                    onChange={(date) => handleDateChange(date, 'timeend')}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    className="form-control"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <FormGroup>
                  <Label for="course">Course</Label>
                  <Input
                    type="select"
                    name="course"
                    id="course"
                    value={formData.course}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select a course</option>
                    {courses.map((course) => (
                      <option key={course._id} value={course._id}>
                        {course.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col span={12}>
                <FormGroup>
                  <Label for="teacher">Teacher</Label>
                  <Input
                    type="select"
                    name="teacher"
                    id="teacher"
                    value={formData.teacher}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select a teacher</option>
                    {teachers.map((teacher) => (
                      <option
                        key={teacher._id}
                        value={teacher._id}
                      >{`${teacher.name} ${teacher.lname}`}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <FormGroup>
                  <Label for="master">Master Franchisor</Label>
                  <Input
                    type="select"
                    name="master"
                    id="master"
                    value={formData.master}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select a master franchisor</option>
                    {masters.map((master) => (
                      <option
                        key={master._id}
                        value={master._id}
                      >{`${master.name} ${master.lname}`}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col span={12}>
                <FormGroup>
                  <Label for="lcf">LCF</Label>
                  <Input
                    type="select"
                    name="lcf"
                    id="lcf"
                    value={formData.lcf}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select an LCF</option>
                    {lcf.map((l) => (
                      <option key={l._id} value={l._id}>{`${l.name} ${l.lname}`}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="active"
                  checked={formData.active}
                  onChange={(e) => setFormData({ ...formData, active: e.target.checked })}
                />{' '}
                Active
              </Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Save
            </Button>{' '}
            <Button color="secondary" onClick={toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={studentModal} toggle={toggleStudentModal} size="lg">
        <ModalHeader toggle={toggleStudentModal}>
          Manage Students for {selectedEvent?.name}
        </ModalHeader>
        <ModalBody>
          <Row gutter={16}>
            <Col span={24}>
              <select
                className="form-control"
                onChange={(e) => handleCalendarEntrySelect(e.target.value)}
                value={selectedCalendarEntry || ''}
              >
                <option value="">Select a calendar entry</option>
                {calendarEntries.map((entry) => (
                  <option key={entry._id} value={entry._id}>
                    {moment(entry.start).format('DD/MM/YYYY HH:mm')} -{' '}
                    {moment(entry.end).format('HH:mm')}
                  </option>
                ))}
              </select>
            </Col>
          </Row>

          {selectedCalendarEntry && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Row gutter={16} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Card>
                    <Title level={4}>Unassigned Students</Title>
                    <Input
                      type="text"
                      placeholder="Filter students..."
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                      style={{ marginBottom: '10px' }}
                    />
                    <CardBody style={{ maxHeight: '450px', overflow: 'scroll', fontSize: 12 }}>
                      <Droppable droppableId="unassigned">
                        {(provided, snapshot) => (
                          <StudentList
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                            {...provided.droppableProps}
                          >
                            {filteredUnassignedStudents.map((student, index) => (
                              <Draggable
                                isDragDisabled
                                key={student._id}
                                draggableId={student._id}
                                index={index}
                              >
                                {(provided1, snapshot1) => (
                                  <StudentItem
                                    ref={provided1.innerRef}
                                    {...provided1.draggableProps}
                                    {...provided1.dragHandleProps}
                                    isDragging={snapshot1.isDragging}
                                  >
                                    {student.name} {student.lname}
                                    {assignedStudents.length < 10 && (
                                      <Button
                                        color="primary"
                                        size="sm"
                                        style={{ marginLeft: 10, float: 'right' }}
                                        onClick={() =>
                                          handleAddStudentToSubsequentEntries(
                                            selectedCalendarEntry,
                                            student._id,
                                          )
                                        }
                                      >
                                        Add
                                      </Button>
                                    )}
                                  </StudentItem>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </StudentList>
                        )}
                      </Droppable>
                    </CardBody>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card>
                    <Title level={4}>Assigned Students ({assignedStudents.length}/10)</Title>

                    <CardBody style={{ maxHeight: '450px', overflow: 'scroll', fontSize: 12 }}>
                      <Droppable droppableId="assigned">
                        {(provided, snapshot) => (
                          <StudentList
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                            {...provided.droppableProps}
                          >
                            {assignedStudents.map((student, index) => (
                              <Draggable
                                isDragDisabled
                                key={student._id}
                                draggableId={student._id}
                                index={index}
                              >
                                {(providedd, snapshott) => (
                                  <StudentItem
                                    ref={providedd.innerRef}
                                    {...providedd.draggableProps}
                                    {...providedd.dragHandleProps}
                                    isDragging={snapshott.isDragging}
                                  >
                                    {student.name} {student.lname}
                                    <Button
                                      color="danger"
                                      size="sm"
                                      style={{ marginLeft: 10, float: 'right' }}
                                      onClick={() =>
                                        handleRemoveStudentFromSubsequentEntries(
                                          selectedCalendarEntry,
                                          student._id,
                                        )
                                      }
                                    >
                                      Remove
                                    </Button>
                                  </StudentItem>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </StudentList>
                        )}
                      </Droppable>
                    </CardBody>

                    <Alert color="warning" isOpen={assignedStudents.length >= 10}>
                      Maximum number of students reached!
                    </Alert>
                  </Card>
                </Col>
              </Row>
            </DragDropContext>
          )}
          <Row gutter={16} style={{ marginTop: '20px' }}>
            <Col span={24}>
              <Card>
                <Title level={4}>Exited Students</Title>
                <CardBody style={{ maxHeight: '200px', overflow: 'scroll', fontSize: 12 }}>
                  {softDeletedStudents.map((student) => (
                    <div key={student._id} style={{ marginBottom: '5px' }}>
                      {student.name} {student.lname} -{' '}
                      {moment(student.latest_deleted_calendar.start).format('DD/MM/YYYY')}
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleStudentModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Groups;
