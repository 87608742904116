import fetchRequest from '../auth/FetchInterceptorRequests'

const studentService = {}

studentService.getAll = function () {
	return fetchRequest({
		url: '/digital/students',
		method: 'get'
	})
}

studentService.get = function (data) {
	return fetchRequest({
		url: `/digital/students/${data.id}`,
		method: 'get'
	})
}
studentService.createStudent = function (data) {
	return fetchRequest({
	  url: '/digital/students',
	  method: 'post',
	  data: data
	})
  }


studentService.statistic = function (data) {
	return fetchRequest({
		url: `/digital/students/statistic`,
		method: 'get'
	})
}
export default studentService;