import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DejaVuSans } from '../fonts/DejaVuSans';

import ComponentCard from '../../../components/ComponentCard';
import userService from '../../../services/UserService';
import countryService from '../../../services/CountryService';

const Lcfs = () => {
  const [users, setUsers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [modal, setModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [passwordError, setPasswordError] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    lname: '',
    email: '',
    password: '',
    confirmPassword: '',
    country: '',
    role: 'lcf',
    fee: '',
    active: '1',
    in_bussines: '1'
  });

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setFormData({
        name: '',
        lname: '',
        email: '',
        password: '',
        confirmPassword: '',
        country: '',
        role: 'lcf',
        fee: '',
        active: '1',
        in_bussines: '1'
      });
    }
  };

  const getUsers = async () => {
    try {
      const response = await userService.getByRole('lcf');
      setUsers(response);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const getCountries = async () => {
    try {
      const response = await countryService.getAll();
      setCountries(response);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  useEffect(() => {
    getUsers();
    getCountries();
  }, []);
  const validatePasswords = (password, repassword) => {
    if (password && repassword && password !== repassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'repassword' || name === 'password') {
      validatePasswords(name === 'password' ? value : formData.password, name === 'repassword' ? value : formData.repassword);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
   
    const userData = { 
      ...formData, 
      active: formData.active === '1',
      fee: parseFloat(formData.fee)
    };
    delete userData.confirmPassword;
    
    try {
      if (formData._id) {
        if (formData.password === '111111') {
          delete userData.password;
        }
        await userService.updateUser(formData._id, userData);
      } else {
        await userService.createUser(userData);
      }
      getUsers();
      toggleModal();
    } catch (error) {
      console.error('Error saving user:', error);
      alert('Error saving user. Please try again.');
    }
  };

  const handleEdit = (user) => {
    setFormData({
      _id: user._id,
      name: user.name,
      lname: user.lname,
      password: '111111',
      repassword: '111111',
      email: user.email,
      country: user.country ? user.country._id : '',
      role: user.role,
      fee: user.fee,
      active: user.active ? '1' : '0',
      in_bussines: user.in_bussines ? '1' : '0'
    });
    setModal(true);
  };
  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await userService.deleteUser(id);
        getUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Error deleting user. Please try again.');
      }
    }
  };
  const columns=[
    {
      Header: 'Name',
      id: 'fullName', // Add this line
      accessor: row => `${row.name} ${row.lname}`,
    },
    {
      Header: 'Email',
      accessor:  row => row.email ? row.email : 'N/A',
    },
    {
      Header: 'Country',
      id: 'countryName', // Add this line
      accessor: row => row.country ? row.country.name : 'N/A',
    },
    {
      Header: 'Fee',
      accessor: 'fee',
      Cell: props => `${props.value}%`
    },
    {
      Header: 'Status',
      accessor: 'active',
      Cell: props => (
        <Badge color={props.value ? 'success' : 'danger'} pill>
          {props.value ? 'Active' : 'Inactive'}
        </Badge>
      )
    },
    {
      Header: 'Actions',
      id: 'actions', // Add this line
      Cell: ({ original }) => (
        <>
          <Button color="info" size="sm" onClick={() => handleEdit(original)}>Edit</Button>
          {' '}
          <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>Delete</Button>
        </>
      )
    }
  ]
  
  const exportPDF = (columnsall,dataToExport) => {
         // eslint-disable-next-line new-cap
    const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4'
      });
    
      // Embed the DejaVuSans font
      doc.addFileToVFS("DejaVuSans.ttf", DejaVuSans);
      doc.addFont("DejaVuSans.ttf", "DejaVuSans", "normal");
      doc.setFont("DejaVuSans"); // Set the custom font
    
      // Add a simple header
      doc.setFillColor(66, 139, 202);
      doc.rect(0, 0, 297, 15, 'F');
      doc.setTextColor(255, 255, 255);
      doc.setFontSize(12);
      doc.text('LCFs Report', 10, 10);
    
      // Add current date to header
      const currentDate = new Date().toLocaleDateString();
      doc.setFontSize(10);
      doc.text(currentDate, 270, 10, { align: 'right' });
    
      // Reset text color for the main content
      doc.setTextColor(0, 0, 0);
    
      const tableStartY = 20;
      doc.autoTable({
        head: [columnsall.map(column => column.Header)],
        body: dataToExport.map(user => [
          `${user.name} ${user.lname}`,
          user.email,
          user.country ? user.country.name : 'N/A',
          `${user.fee}%`,
          user.active ? 'Active' : 'Inactive'
        ]),
        startY: tableStartY,
        styles: { 
          font: 'DejaVuSans', 
          fontSize: 9,
          cellPadding: 3,
        },
        headStyles: { 
          fillColor: [66, 139, 202], 
          textColor: [255, 255, 255],
          fontSize: 10,
          fontStyle: 'bold',
        },
        columnStyles: {
         
        },
        alternateRowStyles: {
          fillColor: [240, 248, 255]
        },
        didDrawPage: function (data) {
          // Footer
          const str = `Page ${doc.internal.getNumberOfPages()}`;
          doc.setFontSize(10);
          doc.text(str, 287, 200, { align: 'right' });
        }
      });
    
      // Add a summary after the table
      const finalY = doc.lastAutoTable.finalY || tableStartY;
      doc.setFontSize(10);
      doc.text(`Total LCFs: ${dataToExport.length}`, 10, finalY + 10);
    
      doc.save("lcfs_report.pdf");
    
  }
  return (
    <>
     <ComponentCard title={`LCFs (${users.length ?? 0})`}>
        <Button color="primary" onClick={toggleModal}>Add New LCF</Button>
        <Button color="secondary" onClick={()=>exportPDF(columns,filteredData.length?filteredData:users)}>Print to PDF</Button>

        <ReactTable
          columns={[
            {
              Header: 'Name',
              id: 'fullName', // Add this line
              accessor: row => `${row.name} ${row.lname}`,
            },
            {
              Header: 'Email',
              accessor: 'email',
            },
            {
              Header: 'Country',
              id: 'countryName', // Add this line
              accessor: row => row.country ? row.country.name : 'N/A',
            },
            {
              Header: 'Fee',
              accessor: 'fee',
              Cell: props => `${props.value}%`
            },
            {
              Header: 'Status',
              accessor: 'active',
              Cell: props => (
                <Badge color={props.value ? 'success' : 'danger'} pill>
                  {props.value ? 'Active' : 'Inactive'}
                </Badge>
              )
            },
            {
              Header: 'In bussines',
              accessor: 'in_bussines',
              Cell: props => (
                <Badge color={props.value ? 'success' : 'danger'} pill>
                  {props.value ? 'Yes' : 'No'}
                </Badge>
              )
            },
            {
              Header: 'Actions',
              id: 'actions', // Add this line
              Cell: ({ original }) => (
                <>
                  <Button color="info" size="sm" onClick={() => handleEdit(original)}>Edit</Button>
                  {' '}
                  <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>Delete</Button>
                </>
              )
            }
          ]}
          defaultPageSize={50}
          className="-striped -highlight myTable"
           
          data={users}
          filterable
        />
      </ComponentCard>

      <Modal isOpen={modal} toggle={toggleModal}>
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>{formData._id ? 'Edit LCF' : 'Add New LCF'}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="country">Country</Label>
              <Input type="select" name="country" id="country" value={formData.country} onChange={handleInputChange} required>
                <option value="">Select a country</option>
                {countries.map(country => (
                  <option key={country._id} value={country._id}>{country.name}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="name">First Name</Label>
              <Input type="text" name="name" id="name" value={formData.name} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="lname">Last Name</Label>
              <Input type="text" name="lname" id="lname" value={formData.lname} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="email" name="email" id="email" value={formData.email} onChange={handleInputChange} required />
            </FormGroup>
               <>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input type="password" name="password" id="password" value={formData.password} onChange={handleInputChange} required />
                </FormGroup>
                <FormGroup>
                <Label for="repassword">Re-enter Password</Label>
                <Input 
              type="password" 
              name="repassword" 
              id="repassword" 
              value={formData.repassword} 
              onChange={handleInputChange}
               invalid={!!passwordError}
              required 
            />       
                        <FormFeedback>{passwordError}</FormFeedback>

                   </FormGroup>
              </>
            
            <FormGroup>
              <Label for="role">Role</Label>
              <Input type="select" name="role" id="role" value={formData.role} onChange={handleInputChange} required>
                <option value="lcf">LCF</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="fee">Fee (%)</Label>
              <Input type="number" name="fee" id="fee" value={formData.fee} onChange={handleInputChange} required min="0" max="100" step="0.01" />
            </FormGroup>
            <FormGroup>
              <Label for="active">Status</Label>
              <Input type="select" name="active" id="active" value={formData.active} onChange={handleInputChange}>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="in_bussines">In business</Label>
              <Input type="select" name="in_bussines" id="in_bussines" value={formData.in_bussines} onChange={handleInputChange}>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">Save</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default Lcfs;