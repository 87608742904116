import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useCountries } from 'use-react-countries';
import moment from 'moment';

import ComponentCard from '../../../components/ComponentCard';
import countryService from '../../../services/CountryService';
import userService from '../../../services/UserService';
import { DejaVuSans } from '../fonts/DejaVuSans';

const Countries = () => {
  const [countries, setCountries] = useState([]);
  const [modal, setModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [masterFranchisors, setMasterFranchisors] = useState([]);
  const { countries: allCountries } = useCountries();

  const [formData, setFormData] = useState({
    name: '',
    code: '',
    vat: '',
    currency: '',
    language: '',
    master: '',
    active: '1',
    notes: ''
  });

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setFormData({
        name: '',
        code: '',
        vat: '',
        currency: '',
        language: '',
        master: '',
        active: '1',
        notes: ''
      });
    }
  };

  const getCountries = async () => {
    try {
      const response = await countryService.getAll();
      setCountries(response);
      setFilteredData(response);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const getMasterFranchisors = async () => {
    try {
      const response = await userService.getByRole('master');
      setMasterFranchisors(response);
    } catch (error) {
      console.error('Error fetching master franchisors:', error);
    }
  };

  useEffect(() => {
    getCountries();
    getMasterFranchisors();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'code') {
      const selectedCountry = allCountries.find(c => c.alpha2 === value);
      if (selectedCountry) {
        setFormData(prevState => ({
          ...prevState,
          name: selectedCountry.name,
          currency: selectedCountry.currency.code,
          language: selectedCountry.languages[0] || ''
        }));
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const countryData = { ...formData, active: formData.active === '1' };
    
    try {
      if (formData._id) {
        await countryService.updateCountry(formData._id, countryData);
      } else {
        await countryService.createCountry(countryData);
      }
      getCountries();
      toggleModal();
    } catch (error) {
      console.error('Error saving country:', error);
    }
  };

  const handleEdit = (country) => {
    setFormData({
      _id: country._id,
      name: country.name,
      code: country.code,
      vat: country.vat,
      currency: country.currency,
      language: country.language,
      master: country.master,
      active: country.active ? '1' : '0',
      notes: country.notes
    });
    setModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this country?')) {
      try {
        await countryService.deleteCountry(id);
        getCountries();
      } catch (error) {
        console.error('Error deleting country:', error);
      }
    }
  };

  const columns = [
    {
      Header: 'Country name',
      accessor: 'name',
      Cell: ({ value, original }) => (
        <>
          {value && <span className={`flag-icon flag-icon-${value.toLowerCase()}`}></span>}
          {' '}{value}
        </>
      )
    },
    {
      Header: 'Vat',
      accessor: 'vat',
      Cell: ({ value }) => `${value}%`
    },
    {
      Header: 'Currency',
      accessor: 'currencie'
    },
    {
      Header: 'Language',
      accessor: 'lang'
    },
    {
        Header: 'Master Franchisor',
        id: 'master',
        accessor: (row) => {
           const masterFranchisor = masterFranchisors.find(mf => mf._id === row?.master);
           return masterFranchisor ? `${masterFranchisor.name} ${masterFranchisor.lname}` : 'N/A';
        },
         
      },
    {
      Header: 'Status',
      accessor: 'active',
      Cell: props => (
        <Badge color={props.value ? 'success' : 'danger'} pill>
          {props.value ? 'Active' : 'Inactive'}
        </Badge>
      )
    },
    {
      Header: 'Created at',
      accessor: 'createdAt',
      Cell: ({ value }) => moment(value).format('DD.MM.YYYY')
    },
    {
      Header: 'Actions',
      Cell: ({ original }) => (
        <>
          <Button color="info" size="sm" onClick={() => handleEdit(original)}>Edit</Button>
          {' '}
          <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>Delete</Button>
        </>
      )
    }
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    // eslint-disable-next-line new-cap
    const doc = new jsPDF(orientation, unit, size);

    doc.addFileToVFS("DejaVuSans.ttf", DejaVuSans);
      doc.addFont("DejaVuSans.ttf", "DejaVuSans", "normal");
      doc.setFont("DejaVuSans"); // Set the custom font
    doc.setFontSize(12);

    const title = "Countries Report";
    const headers = columns.map(column => column.Header);

    const data = filteredData.map(item => [
      item.name,
      `${item.vat}%`,
      item.currencie,
      item.lang,
      item.master ? `${masterFranchisors.find(l => l._id === item.master).name} ${masterFranchisors.find(l => l._id ===  item.master).lname}`: 'N/A',
      item.active ? 'Active' : 'Inactive',
      moment(item.createdAt).format('DD.MM.YYYY')
    ]);

    const content = {
      startY: 50,
      head: [headers],
      body: data,
      styles: { 
        font: 'DejaVuSans', 
        fontSize: 9,
        cellPadding: 3,
      },
    };

    doc.text(title, 40, 40);
    doc.autoTable(content);
    doc.save("countries_report.pdf")
  }

  return (
    <>
      <ComponentCard title={`Countries (${countries.length ?? 0})`}>
        <div style={{marginBottom: '10px'}}>
          <Button color="primary" onClick={toggleModal} style={{marginRight: '10px'}}>Add New Country</Button>
          <Button color="secondary" onClick={exportPDF}>Print to PDF</Button>
        </div>
        <ReactTable
          columns={columns}
          data={countries}
          filterable
          defaultPageSize={10}
          className="-striped -highlight myTable"
         /*  onFilteredChange={(filtered, column, value) => {
            const filteredData = countries.filter(item => {
              return filtered.every(filter => {
                const columnValue = columns.find(c => c.accessor === filter.id).accessor(item);
                return columnValue.toLowerCase().includes(filter.value.toLowerCase());
              });
            });
            setFilteredData(filteredData);
          }} */
        />
      </ComponentCard>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>{formData._id ? 'Edit Country' : 'Add New Country'}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="code">Country</Label>
              <Input type="select" name="code" id="code" value={formData.code} onChange={handleInputChange} required>
                <option value="">Select a country</option>
                {allCountries.map(country => (
                  <option key={country.alpha2} value={country.alpha2}>
                    {country.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="master">Master franchisor</Label>
              <Input type="select" name="master" id="master" value={formData.master} onChange={handleInputChange} required>
                <option value="">Select Master Franchisor</option>
                {masterFranchisors.map(mf => (
                  <option key={mf._id} value={mf.email}>{mf.email}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="currency">Currency</Label>
              <Input type="text" name="currency" id="currency" value={formData.currency} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="vat">VAT</Label>
              <Input type="number" name="vat" id="vat" value={formData.vat} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="notes">Notes</Label>
              <Input type="textarea" name="notes" id="notes" value={formData.notes} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="active">Active</Label>
              <Input type="select" name="active" id="active" value={formData.active} onChange={handleInputChange}>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">Save</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default Countries;