import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { Switch } from 'antd';
import { Cell } from 'jspdf-autotable';
import moment from 'moment';

import { ChromePicker } from 'react-color';
import ComponentCard from '../../../components/ComponentCard';
import licenseService from '../../../services/LicenseService';

const Licenses = () => {
  const [licenses, setLicenses] = useState([]);
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    temporary: false,
    duration: 0,
    color: '#000000',
    notes: '',
    active: true
  });
  const [showColorPicker, setShowColorPicker] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setFormData({
        title: '',
        temporary: false,
        duration: 0,
        color: '#000000',
        notes: '',
        active: true
      });
      setShowColorPicker(false);
    }
  };

  const getLicenses = async () => {
    try {
      const response = await licenseService.getAll();
      setLicenses(response);
    } catch (error) {
      console.error('Error fetching licenses:', error);
    }
  };

  useEffect(() => {
    getLicenses();
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleColorChange = (color) => {
    setFormData(prevState => ({
      ...prevState,
      color: color.hex
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (formData._id) {
        await licenseService.updateLicense(formData._id, formData);
      } else {
        await licenseService.createLicense(formData);
      }
      getLicenses();
      toggleModal();
    } catch (error) {
      console.error('Error saving license:', error);
    }
  };

  const handleEdit = (license) => {
    setFormData({
      _id: license._id,
      title: license.title,
      temporary: license.temporary,
      duration: license.duration,
      color: license.color,
      notes: license.notes,
      active: license.active
    });
    setModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this license?')) {
      try {
        await licenseService.deleteLicense(id);
        getLicenses();
      } catch (error) {
        console.error('Error deleting license:', error);
      }
    }
  };

  const handleToggleActive = async (id, active) => {
    try {
      await licenseService.updateLicense(id, { active });
      getLicenses();
    } catch (error) {
      console.error('Error toggling license status:', error);
    }
  };
  const columns = [
    {
      Header: 'License name',
      id: 'name',
        accessor: (row) => row.name,

    },
    {
      Header: 'Temporary',
      accessor: 'temp',
      Cell: ({ value }) => (
        <Badge color={value ? 'warning' : 'success'} pill>
          {value ? 'Temporary' : 'Permanent'}
        </Badge>
      )
    },
    {
      Header: 'License duration (months)',
      accessor: 'months',
      Cell : ({ value }) => `${value}`
    },
    {
      Header: 'Color',
      accessor: 'color',
      Cell: ({ value }) => (
        <div style={{ 
          backgroundColor: value, 
          width: '50px', 
          height: '20px', 
          borderRadius: '4px',
          border: '1px solid #ddd'
        }}></div>
      )
    },
    {
      Header: 'Status',
      accessor: 'active',
      Cell: props => (
        <Badge color={props.value ? 'success' : 'danger'} pill>
          {props.value ? 'Active' : 'Inactive'}
        </Badge>
      )
    },
    {
      Header: 'Created at',
      accessor: 'createdAt',
      Cell: ({ value }) => moment(value).format('DD.MM.YYYY')
    },
    {
      Header: 'Actions',
      Cell: ({ original }) => (
        <>
          <Button color="info" size="sm" onClick={() => handleEdit(original)}>Edit</Button>
          {' '}
          <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>Delete</Button>
          {' '}
          <Switch
            checked={original.active}
            onChange={() => handleToggleActive(original._id, !original.active)}
          />
        </>
      )
    }
  ];


  return (
    <>
      <ComponentCard title={`Licenses (${licenses.length ?? 0})`}>
        <Button color="primary" onClick={toggleModal} style={{marginBottom: '10px'}}>Add New License</Button>
        <ReactTable
          columns={columns}
          data={licenses}
          filterable
          defaultPageSize={10}
          className="-striped -highlight myTable"
        />
      </ComponentCard>

      <Modal isOpen={modal} toggle={toggleModal}>
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>{formData._id ? 'Edit License' : 'Add New License'}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input type="text" name="title" id="title" value={formData.title} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" name="temporary" checked={formData.temporary} onChange={handleInputChange} />{' '}
                Temporary
              </Label>
            </FormGroup>
            {formData.temporary && (
              <FormGroup>
                <Label for="duration">Duration (months)</Label>
                <Input type="number" name="duration" id="duration" value={formData.duration} onChange={handleInputChange} min="0" />
              </FormGroup>
            )}
            <FormGroup>
              <Label for="color">Color</Label>
              <div>
                <div 
                  style={{
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowColorPicker(!showColorPicker)}
                >
                  <div style={{
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: formData.color,
                  }} />
                </div>
                {showColorPicker && (
                  <ChromePicker 
                    color={formData.color}
                    onChange={handleColorChange}
                  />
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="notes">Notes</Label>
              <Input type="textarea" name="notes" id="notes" value={formData.notes} onChange={handleInputChange} />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">Save</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default Licenses;